import { ThemeProvider } from '@/hooks/useTheme';
import { Authenticated } from '@/layouts/Authenticated';
import { GradientBackground } from '@/components/background/GradientBackground';
import { HollowText } from '@/components/typography/HollowText';
import React from 'react';
import { Blob11 } from '@/components/blob/Blob11';
import { Blob12 } from '@/components/blob/Blob12';
import { LowImpactButton } from '@/components/button/LowImpactButton';
import { HighImpactButton } from '@/components/button/HighImpactButton';
import { Icon } from '@/components/icon/Icon';
import { BodyTextLg, BodyTextXL } from '@/components/typography/body/BodyText';
import { FooterGlobal } from '../footer/FooterGlobal';

export interface Props {
  status: number;
  message: string;
  buttonLink: string;
  buttonLabel: string;
  title: string;
}

export default function ErrorPageContent({
  status,
  title,
  message,
  buttonLink,
  buttonLabel
}: Props) {
  return (
    <ThemeProvider theme="light">
      <Authenticated className="h-full" transparent={false}>
        <GradientBackground theme="light" className="w-full" variant="violet">
          <div className="relative h-full w-full overflow-x-hidden overflow-y-auto">
            <div className="inset-x-0 mx-auto flex justify-center px-4 pt-4 sm:bg-black">
              <HollowText
                theme="light"
                text={title}
                className="text-center text-5xl tracking-wide md:text-7xl"
              />
            </div>
            <div className="relative flex justify-center px-4">
              <Blob11 className="absolute bottom-[80%] hidden w-[150%] sm:block lg:w-full" />
              <Blob12 className="absolute -top-[35%] sm:hidden" />
              <div className="relative z-30 text-center sm:max-w-lg md:-mt-6 xl:mt-0">
                <Icon
                  icon="error-hand"
                  theme="light"
                  className="mb-10 max-w-[13rem] sm:max-w-md md:max-w-full"
                />
                <BodyTextLg className="md:hidden">{message}</BodyTextLg>
                <BodyTextXL className="hidden md:block">{message}</BodyTextXL>
                <div className="flex flex-col-reverse justify-center space-x-0 py-8 sm:items-center sm:space-x-2 sm:space-y-0 md:flex-row">
                  {(status === 500 || status === 404 || status === 403) && (
                    <>
                      <LowImpactButton
                        label="Go Back"
                        rounding="square"
                        href="#"
                        onClick={() => history.back()}
                        prefixIcon="chevron-left"
                      />
                    </>
                  )}

                  <HighImpactButton
                    label={buttonLabel}
                    href={buttonLink}
                    suffixIcon="chevron-right"
                    rounding={status === 401 || status === 200 ? 'pill' : 'square'}
                  />
                </div>
              </div>
            </div>
            <FooterGlobal centered scrollToTop={false} className="pb-12 pt-24" />
          </div>
        </GradientBackground>
      </Authenticated>
    </ThemeProvider>
  );
}
